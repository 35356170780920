import * as _isCoreModule2 from "is-core-module";

var _isCoreModule = "default" in _isCoreModule2 ? _isCoreModule2.default : _isCoreModule2;

var exports = {};
var isCoreModule = _isCoreModule;

exports = function isCore(x) {
  return isCoreModule(x);
};

export default exports;